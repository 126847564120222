
import { Component } from "vue-property-decorator";
import Table from "@/components/Table.vue";
import Header from "@/components/Header.vue";
import ManagementFooter from "@/components/ManagementFooter.vue";
import CheckAdminLogin from "@/utils/CheckAdminLogin";

@Component({
  name: "UsersManagement",
  components: {
    Table,
    Header,
    ManagementFooter,
  },
})
export default class UsersManagement extends CheckAdminLogin {}
